@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url("./assets/Fonts/Inter-Regular.ttf") format("truetype");
}
#root {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden !important;
}
.overFlowAuto {
  overflow: auto !important;
}
.primary-row {
  background-color: #00970525;
}
.MuiAutocomplete-option[aria-selected="true"] {
  background-color: #00648044 !important;
  font-weight: 600 !important;
  color: #004d62;
}
.hover {
  cursor: pointer;
}
html,
body,
#app {
  font-size: 14px;
  margin: 0;
}
.back-red {
  background: #ffcbcb !important;
  background-color: #ffcbcb !important;
}
html {
  width: 100vw;
  scroll-behavior: smooth;
}
input {
  min-width: 0;
}
::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
  transition: all 0.4s ease;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: #b5b5b56c;
  border-radius: 10px;
  transition: all 0.4s ease;
}
::-webkit-scrollbar-thumb {
  background: #2c7385;
  border-radius: 10px;
  transition: all 0.4s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: #0386b5;
  transition: all 0.4s ease;
  cursor: pointer;
  border-radius: 0px;
}
.hidden::-webkit-scrollbar {
  width: 1px;
  border-radius: 0px;
  height: 1px;
}
.hidden::-webkit-scrollbar-track {
  background: #0041526c;
  border-radius: 0px;
}
.hidden::-webkit-scrollbar-thumb {
  background: #2c7385;
  border-radius: 0px;
  transition: all 0.4s ease;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.highcharts-button-box {
  stroke: #004d62 !important;
}
.highcharts-button-symbol {
  stroke: #004d62 !important;
}
.highcharts-menu {
  background-color: #ecf2ff !important;
  border: 1px solid #004d62 !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0 0.5rem #004d62 !important;
  color: #004d62 !important;
  padding: 0.2rem !important;
}
.highcharts-menu-item {
  font-size: 0.9rem !important;
  color: #004d62 !important;
  font-weight: 700 !important;
  transition: all 0.2s ease-in-out;
}
.highcharts-menu-item:hover {
  background-color: #004d62 !important;
  color: #ecf2ff !important;
}
.highcharts-range-selector-buttons > .highcharts-label {
  display: none;
}
.texture p {
  width: 100px;
  height: 190px;
  background-color: #00566e;
  position: absolute;
}
.one {
  top: 0;
  left: 0;
  box-shadow:
    100px -60px 0 0 #00566e,
    200px -120px 0 0 #00566e;
}
.two {
  bottom: 0;
  right: 0;
  box-shadow:
    -100px 50px 0 0 #00566e,
    -200px 100px 0 0 #00566e,
    -300px 150px 0 0 #00566e,
    -400px 175px 0 0 #00566e;
}
.textUnderlineLogin::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 8px;
  background-color: #63a387;
  top: 33px;
  transform: skew(-40deg);
  rotate: -3deg;
  z-index: 1;
}
.deactivate-header {
  color: #000;
  border-radius: 0.375rem;
}
.filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px;
  background-color: #e3e8ef;
  color: #000;
}
.filter-item p {
  margin: 0;
  padding-right: 5px;
}
html.is-changing .transition-fade {
  transition: opacity 0.25s;
  opacity: 1;
}
html.is-animating .transition-fade {
  opacity: 0;
}
.small-case-row-background {
  background-color: rgb(255, 195, 195);
}
progress {
  border-radius: 4px;
  width: 100%;
  height: 16px;
}
.ck .ck-powered-by {
  display: none;
}
.ck {
  font-family: Inter;
}
